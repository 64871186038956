export const routes = [
    {
        path: '/payments',
        name: 'payments.browse',
        component: () => import(/* webpackChunkName: "PaymentsBrowse" */ '@/views/app/PaymentsBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/payments/:id/clone',
        name: 'payments.clone',
        component: () => import(/* webpackChunkName: "PaymentsActions" */ '@/views/app/PaymentsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/payments/create',
        name: 'payments.create',
        component: () => import(/* webpackChunkName: "PaymentsActions" */ '@/views/app/PaymentsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/payments/:id/edit',
        name: 'payments.edit',
        component: () => import(/* webpackChunkName: "PaymentsActions" */ '@/views/app/PaymentsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/payments/:id/delete',
        name: 'payments.delete',
        component: () => import(/* webpackChunkName: "PaymentsActions" */ '@/views/app/PaymentsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
    {
        path: '/payments/:id/show',
        name: 'payments.show',
        component: () => import(/* webpackChunkName: "PaymentsActions" */ '@/views/app/PaymentsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
]